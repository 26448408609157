/*
----------------
index.scss
----------------
The web server loads index.html which is very sparse and doesn't do much besides
load the bundle.js and bundle.css. Both of those are generated by webpack at
build time. The bundle.css is created starting from this file, which in turn
imports and compiles all other scss.
*/

/* Bootstrap
–––––––––––––––––––––––––––––––––––––––––––––––––– */
// @import "src/scss/bootstrap/bootstrap.scss";

/* Core
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import "_variables";
@import "lib/normalize";
@import "lib/grid";
@import "lib/utilities";
@import "global";

/* Admin
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@import "../launchpad/admin/Admin.scss";



/* Components
––––––––––––––––––––––––––––––––––––––––––––––––––
 This section will be automatically populated as you
 copy/create components in `src/components/` and bad imports
 will be automatically removed
*/

// active-scss: begin components

@import "../components/_shared/layout-grid/Grid.scss";
@import "../components/_shared/partial-accordion/Accordion.scss";
@import "../components/_shared/partial-auto-slider/AutoSlider.scss";
@import "../components/_shared/partial-collapsible/Collapsible.scss";
@import "../components/_shared/partial-dynamic-list/DynamicList.scss";
@import "../components/_shared/partial-hero/Hero.scss";
@import "../components/_shared/partial-multi-image/MultiImage.scss";
@import "../components/_shared/partial-slideshow/Slideshow.scss";
@import "../components/layout/Footer.scss";
@import "../components/layout/Header.scss";
@import "../components/pages/Articles.scss";
@import "../components/pages/Contact.scss";
@import "../components/pages/Error404.scss";
@import "../components/pages/FAQ.scss";
@import "../components/pages/Features.scss";
@import "../components/pages/Home.scss";
@import "../components/pages/Privacy_Eula.scss";
@import "../components/pages/Testimonials.scss";
@import "../components/pages/WebApp/ImagePanel.scss";
@import "../components/pages/WebApp/MapPanel.scss";
@import "../components/pages/WebApp/ProjectPanel.scss";
@import "../components/pages/WebApp/Settings.scss";
@import "../components/pages/WebApp/WebApp.scss";
@import "../components/pages/WebApp/tools/tools.scss";
@import "../components/pages/admin/admin.scss";
@import "../components/sections/CTA.scss";
@import "../components/sections/partial-hero/Hero.scss";
@import "../components/templates/CustomPage.scss";
@import "../components/templates/Stub.scss";
@import "../components/widgets/AppStoreButtons.scss";
@import "../components/widgets/DropDown.scss";
@import "../components/widgets/widgets.scss";

// active-scss: end components

@import "theme";
